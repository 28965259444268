import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

const base = '/api/auth'
const modules = '/oauth'
const path = base + modules

// request(REQ) Api  --------------------------------
const loginREQ = base + '/login' // 登入
const twoFactorREQ = path + '/twoFactor' // 二次驗證

const registerREQ = path + '/register' // 註冊
const gmailVerifyREQ = path + '/verify?token=' // 郵箱驗證
const resendMailREQ = path + '/sendVerificationEmail' // 註冊 重發驗證信

const forgetPwdREQ = path + '/forgotPassword' // 忘記密碼
const resetPwdREQ = path + '/resetPassword' // 重設密碼

const logoutREQ = base + '/logout' // 登出
const logoutUserId = base + '/device/logout' // 登出指定的設備

const googleLoginREQ = path + '/login/google' // google登入
const googleBindREQ = base + '/bind/google' // google綁定

// request(REQ) Function  --------------------------------

/**
 * 登入
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const loginSubmitFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + loginREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 二次驗證
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const verifySubmitFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + twoFactorREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 註冊
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
export const registerSubmitFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + registerREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 郵箱驗證
 * @param {String} token 驗證token
 * @param {Object} callback 回調函數
 * @param {Object} callbackError 回調函數error
 * @returns
 */
export const gmailVerfySubmitFn = (token, callback, callbackError) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + gmailVerifyREQ + token, {
    method: 'GET',
    onResponse: callback,
    onResponseError: callbackError
  })
}

/**
 * 註冊 重發驗證信
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const resendMailFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + resendMailREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 忘記密碼
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const forgetPwdSubmitFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + forgetPwdREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 重設密碼
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const resetPwdSubmitFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + resetPwdREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 登出
 * @param {Object} callback 回調函數
 * @returns
 */
export const logoutFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + logoutREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 登出指定的設備
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const logoutUserIdFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(logoutUserId, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * Google登入
 * @param {*} callback
 * @returns
 */
export const googleLoginFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + googleLoginREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * Google綁定
 * @param {*} callback
 * @returns
 */
export const googleBindFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + googleBindREQ, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 開啟Google登入頁面
 * @param {*} event 事件
 * @param {*} callback 回調函數
 */
let googleListener = null
export const openGoogleFn = (event, callback) => {
  const width = 500
  const height = 800
  const left = (window.innerWidth - width) / 2
  const top = (window.innerHeight - height) / 2
  const features = `width=${width},height=${height},left=${left},top=${top},status=no,scrollbars=yes,resizable=yes,toolbar=no,menubar=no,location=no`

  const newWindow = window.open(event, 'GoogleLogin', features)

  if (newWindow) {
    // 移除監聽器
    if (googleListener != null) {
      window.removeEventListener('message', googleListener)
    }

    googleListener = (event) => {
      const res = JSON.parse(event.data)
      callback(res)
      window.removeEventListener('message', googleListener)
    }

    // 添加新的監聽器
    window.addEventListener('message', googleListener, { once: true })
  }
}
